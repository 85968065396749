import { setLoadedAction, setLoadingAction } from "store/actions/api";
import {
  setReportAction,
  setRequestsAction,
  setStatsAction,
} from "store/actions/requests";
import { getQueryString, requestKey } from "utils/functions";
import api from "./api";

export const apiGetRequests = (
  store: any,
  location: any,
  page?: any,
  callback: (error: string | null | undefined) => void = () => void null,
  recents: boolean = false
) => {
  const key = requestKey();
  store.dispatch(setLoadingAction(key));

  const _qs = getQueryString("q", location);
  const status = getQueryString("status", location);

  const url = "/requests/";
  const q = recents === true ? "recents" : _qs;

  api
    .get(url, { params: { page: page <= 0 ? 1 : page, q, status } })
    .then((data) => {
      store.dispatch(setRequestsAction(data.data));
    })
    .catch((error) => {
      callback(error.response?.data || "Network Error");
    })
    .finally(() => {
      store.dispatch(setLoadedAction(key));
      callback(null);
    });
  return key;
};

export const apiGetStats = (dispatch: any, final: () => void) => {
  const key = requestKey();
  dispatch(setLoadingAction(key));

  api
    .get("/stats/")
    .then((response) => {
      dispatch(setStatsAction(response.data));
    })
    .finally(() => {
      final();
      setLoadedAction(key);
    });

  return key;
};

export const apiGetReport = (store: any) =>
  api
    .get(
      `/request/${getQueryString("package_id")}/${getQueryString(
        "request_ref"
      )}/`
    )
    .then((data) => {
      store.dispatch(setReportAction(data.data));
    });
