import { arrayToObject } from "utils/functions";
import * as types from "../types";

const session_user: any = sessionStorage.getItem("user");

let defaultState: any = {
  loading: [],
  report: {},
  user: {
    full_name: null,
    first_name: null,
    username: null,
    email: null,
    ...JSON.parse(session_user),
    access: sessionStorage.getItem("_dkdn_.sess_google_adsense____"),
  },
  profile: {
    client_first_name: null,
    client_last_name: null,
  },
  colors: {
    "11": "green",
    "33": "blue",
    "44": "blue",
    "00": "rgb(51, 0, 118)",
  },
  stats: {
    credits: 0,
    new: 0,
    interim: 0,
    final: 0,
    in_progress: 0,
    recent: [],
  },
  modules: {
    88: {
      name: "Company Search",
      package_id: 31,
      url: "company",
      label: "Company",
      sort: 1,
    },
    93: {
      name: "Business Search",
      package_id: 34,
      url: "business",
      label: "Business",
      sort: 2,
    },
    91: {
      name: "Limited Liability Patnerships (LLP)",
      package_id: 37,
      url: "llp",
      label: "LLP",
      sort: 3,
    },
    95: {
      name: "Companies Limitted By Guarantee (CLG)",
      package_id: 40,
      url: "clg",
      label: "CLG",
      sort: 4,
    },
    90: {
      name: "Societies Search",
      package_id: 36,
      url: "societies",
      label: "Society",
      sort: 5,
    },
    97: {
      //
      name: "NGO Search",
      package_id: 33,
      url: "ngo",
      label: "NGO",
      sort: 6,
    },
    92: {
      name: "Community Based Organisation (CBO)",
      package_id: 38,
      url: "cbo",
      label: "CBO",
      sort: 7,
    },
    89: {
      name: "Cooperative SACCO",
      package_id: 35,
      url: "sacco",
      label: "SACCO",
      sort: 8,
    },
    94: {
      name: "Trusts Search",
      package_id: 39,
      url: "trusts",
      label: "Trust",
      sort: 9,
    },
    96: {
      name: "International Company Search",
      package_id: 41,
      url: "international",
      label: "Company",
      sort: 10,
    },
  },
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
};

export default function global(
  state: typeof defaultState = defaultState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.SET_USER:
      return { ...state, user: { ...state.user, ...action.payload } };
    case types.LOADING:
      return { ...state, loading: [...state.loading, action.payload] };
    case types.LOADED:
      return {
        ...state,
        loading: [...state.loading].filter((i: any) => i !== action.payload),
      };
    case types.SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case types.SET_STATS:
      let incoming_recent = arrayToObject(
        action.payload.recent || [],
        "request_ref_number"
      );
      const recent: any = { ...incoming_recent, ...state?.stats?.recent };

      return {
        ...state,
        stats: {
          ...state.stats,
          ...action.payload,
          recent,
          credits:
            action.payload.credits == null
              ? state.stats.credits
              : action.payload.credits,
        },
      };

    case types.SET_CREDITS:
      if (!action.payload || action == null) return state;
      return { ...state, stats: { ...state.stats, credits: action.payload } };

    case types.SET_REPORT:
      return { ...state, report: action.payload };
    default:
      return state;
  }
}

export type GlobalState = typeof defaultState;
