import { filterObjectArray, getQueryString, sortBy } from "utils/functions";
import BarChartIcon from "@material-ui/icons/BarChart";
import { Component } from "react";
import { store } from "store";
import { withRouter } from "react-router";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { RouteComponentProps } from "react-router-dom";
import { apiGetRequests } from "api/requests";
import { Request, RequestData } from "store/reducers/requests";
import { throttle } from "lodash";
import { HeadFooterRow, Row } from "components/home/abstract";

class _Requests extends Component<RouteComponentProps> {
  state = { key: "" };
  constructor(props: any) {
    super(props);
    this.getRequestData = this.getRequestData.bind(this);
    this.setState = this.setState.bind(this);
    this.setPage = this.setPage.bind(this);
    this.search = this.search.bind(this);
  }
  // SEARCH
  search = throttle(
    (keyword: string) => {
      if (String(keyword).length < 4) return;
      this.getRequestData("search", keyword);
    },
    3000,
    { trailing: true }
  );

  get requestData(): any {
    return this.request.data;
  }
  get request(): Request {
    return store.getState().requests;
  }

  getRequestData(page?: string, keyword?: string) {
    if (this.state.key) return;
    let key;
    const updateRequest = (data: any) => {
      this.setState({ ...this.state, key: null, error: data });
    };

    if (page === "next" && this.request.next) {
      key = apiGetRequests(
        store,
        this.props.location,
        this.page + 1,
        updateRequest
      );
    } else if (page === "prev" && this.request.prev) {
      key = apiGetRequests(
        store,
        this.props.location,
        this.page - 1,
        updateRequest
      );
    } else {
      key = apiGetRequests(store, this.props.location, 1, updateRequest);
    }

    if (key) this.setState({ ...this.state, key });
  }
  setPage = this.getRequestData;

  get page() {
    return this.request.page ? this.request.page : 1;
  }

  unlisten: any = undefined;
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (
        location.pathname.startsWith("/requests") &&
        this.q !== getQueryString("q", location)
      ) {
        this.getRequestData();
      }
    });
    this.getRequestData();
  }
  componentWillUnmount() {
    this.unlisten();
  }

  get loading() {
    return this.state.key ? true : false;
  }

  get q() {
    return getQueryString("q", this.props.location);
  }

  render() {
    let data: RequestData[] = Object.values(this.requestData);

    return (
      <div
        className="container-fluid bg-white shadow my-4 py-3 px-3"
        style={{ overflowX: "scroll" }}
      >
        {this.loading && (
          <span className="spinner-border float-right m-4 spinner-sm text-info"></span>
        )}
        <DataTable
          className="small"
          data={data}
          title={`${this.q === "all" ? "ALL" : "MY"} REPORTS`}
          page={this.page}
          setPage={this.setPage}
          next={this.request.next}
          search={this.search}
        />
      </div>
    );
  }
}
export const Requests = withRouter(_Requests);

class DataTable extends Component<{
  data: any;
  title: string;
  page: number;
  next: null | string;
  setPage: (e: any) => void;
  search?: (resultlength: string) => any;
  className?: string;
}> {
  state = { sortField: { field: "", sorting: "" }, filter: "" };
  constructor(props: any) {
    super(props);
    this.setFilter = this.setFilter.bind(this);
    this.setSortField = this.setSortField.bind(this);
  }

  get requestData() {
    const filter: string = this.state.filter;

    let _recentReports: any = sortBy(
      this.props.data,
      this.state.sortField["field"],
      this.state.sortField["sorting"]
    );

    _recentReports =
      filter && filter.length
        ? filterObjectArray(this.props.data, filter)
        : _recentReports;

    if (filter && this.props.search) this.props.search(filter);

    return _recentReports;
  }

  setSortField(field: string) {
    let _already_set = this.state.sortField["field"] === "field" ? true : false;

    let sorting =
      _already_set && this.state.sortField["sorting"] === "asc"
        ? "desc"
        : this.state.sortField["sorting"] === "asc"
        ? "desc"
        : "asc";
    this.setState({ sortField: { field, sorting } });
  }
  get sortWith(): object {
    return this.state.sortField;
  }
  setFilter(filter: string) {
    this.setState({ ...this.state, filter });
  }

  render() {
    return (
      <div className="text-muted">
        <p className="font-weight-bold pt-3">
          <BarChartIcon />
          {this.props.title}
          {this.state.filter}
        </p>
        <div className="d-flex justify-content-end mb-1">
          <div>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search"
              onChange={(e) => this.setFilter(e.target.value)}
            />
          </div>
        </div>
        <table
          className={`table table-striped data-table ${this.props.className}`}
        >
          <thead>
            <HeadFooterRow {...{ setSortField: this.setSortField }} />
          </thead>
          <tbody>
            {this.requestData && this.requestData.length ? (
              this.requestData.map((obj: any, index: number) => (
                <Row key={index} {...{ obj, index }} />
              ))
            ) : (
              <tr>
                <td align="center" colSpan={10}>
                  <h6 className="text-muted text center">
                    {this.state.filter
                      ? `Nothing containing "${this.state.filter}" Found`
                      : "Nothing here yet"}
                  </h6>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <HeadFooterRow />
          </tfoot>
        </table>
        <div className="pagination py-2">
          <Pagination
            setPage={this.props.setPage}
            page={this.props.page}
            next={this.props.next}
          />
        </div>
      </div>
    );
  }
}

class Pagination extends Component<{
  setPage: (e: any) => void;
  page: number;
  next: null | string;
  disable?: boolean;
}> {
  get styles() {
    return {
      button: {
        paddingLeft: ".5rem",
        paddingRight: ".5rem",
        display: "flex",
        alignItems: "center",
        fontWeight: 300,
        // letterSpacing: "-.8px",
      },
    };
  }
  render() {
    return (
      <div className=" py-3 d-flex justify-content-center w-100">
        <div className="btn-group">
          <button
            className="btn btn-sm border-dark"
            style={this.styles.button}
            value="prev"
            onClick={() => this.props.setPage("prev")}
            disabled={this.props.page <= 1 ? true : false}
          >
            <ChevronLeft />
          </button>
          <button
            className="btn btn-sm text-dark mono"
            style={this.styles.button}
          >
            Page {this.props.page ? this.props.page : 1}
          </button>
          <button
            className="btn btn-sm border-dark"
            style={this.styles.button}
            onClick={() => this.props.setPage("next")}
            value="next"
            disabled={this.props.next ? false : true}
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    );
  }
}
