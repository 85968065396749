export const SET_USER = "setUser";
export const LOADING = "loading";
export const LOADED = "loaded";

export const SET_REQUESTS = "getRequests";

export const SET_PROFILE = "setProfile";

export const SET_STATS = "setStats";
export const SET_CREDITS = "setCredits";

export const SET_REPORT = "setReport";

export const ADD_NOTIFICATION = "addNotification";
export const REMOVE_NOTIFICATION = "removeNotification";
