import { AxiosError } from "axios";
import { setLoadedAction, setLoadingAction } from "store/actions/api";
import { setProfile } from "store/actions/auth";
import { addNotificationAction } from "store/actions/notification";
import { setRequestsAction, setStatsAction } from "store/actions/requests";
import { clearToken, setToken } from "utils/auth.token";
import { requestKey } from "utils/functions";
import api from "./api";

export const ApiLogin = (info: any) => {
  const request = api.post("auth/login/", info);
  request.then((data) => {
    if (data.status === 200) {
      setToken(data.data);
    } else {
      clearToken();
    }
    return data;
  });

  return request;
};

export const ApiNewRequest = (
  data: any,
  store: any,
  callback: (error: string | null | undefined) => void = () => void null
) => {
  const key: string = requestKey();
  const _callback = callback;

  store.dispatch(setLoadingAction(key));
  api
    .post("request/", data)
    .then((response) => {
      const body = "Your request has been queued for processing.";
      store.dispatch(addNotificationAction(body, "success"));
      store.dispatch(setRequestsAction([response.data]));

      const state = store.getState();

      store.dispatch(
        setStatsAction({
          ...state.global?.stats,
          new: state.global?.stats.new + 1,
          recent: [response.data],
        })
      );
    })
    .catch((error: AxiosError) => {
      const body = `Request with Reg No. ${
        error.request?.data && error.request?.data["registration_number"]
          ? error.request.data["registration_number"]
          : "-"
      } failed!. Please try again.`;
      store.dispatch(addNotificationAction(body, "danger"));
      callback(error.response?.data || "Network Error");
    })
    .finally(() => {
      store.dispatch(setLoadedAction(key));
      _callback(null);
    });
  return key;
};

export const apiGetProfile = (store: any, callback: (data: any) => void) => {
  const key: string = requestKey();

  store.dispatch(setLoadingAction(key));
  api
    .get("/auth/profile/")
    .then((data) => {
      store.dispatch(setProfile(data.data));
      callback(data);
    })
    .finally(() => {
      store.dispatch(setLoadedAction(key));
    });

  return key;
};

export const apiUpdateProfile = (data: object, callback: any) => {
  const key: string = requestKey();
  api.put("/auth/profile/", data).finally(() => callback());
  return key;
};
