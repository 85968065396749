import { filterObjectArray, sortBy } from "utils/functions";
import BarChartIcon from "@material-ui/icons/BarChart";
import { Component } from "react";
import { apiGetStats } from "api/requests";
import { connect } from "react-redux";
import { State } from "store";
import { Row, HeadFooterRow } from "./abstract";
class _Dashboard extends Component<{
  stats: any;
  dispatch: any;
}> {
  state = { loading: true };
  componentDidMount() {
    this.setState({ loading: true }, () =>
      apiGetStats(this.props.dispatch, () => {
        this.setState({ loading: false });
      })
    );
  }
  render() {
    return (
      <>
        <div
          className="w-100 bg-white shadow my-4 py-3 px-3 "
          style={{ overflowX: "scroll" }}
        >
          {this.state.loading && this.props.stats?.recent?.length <= 0 ? (
            <div className="fixed-top vw-100 vh-100 d-flex align-items-center justify-content-center">
              <div className="spinner-border"></div>
            </div>
          ) : (
            <DataTable
              className="small"
              data={Object.values(this.props?.stats?.recent)}
              title={`RECENT REPORTS`}
            />
          )}
        </div>
      </>
    );
  }
}
export const Dashboard = connect((state: State) => ({
  stats: state.global.stats,
}))(_Dashboard);

class DataTable extends Component<{
  data: any;
  title: string;
  className?: string;
}> {
  state = { sortField: { field: "request_date", sorting: "" }, filter: "" };
  constructor(props: any) {
    super(props);
    this.setFilter = this.setFilter.bind(this);
    this.setSortField = this.setSortField.bind(this);
  }

  get recentReports() {
    const filter: string = this.state.filter;

    let _recentReports: any = sortBy(
      this.props.data,
      this.state.sortField["field"],
      this.state.sortField["sorting"]
    );

    _recentReports =
      filter && filter.length
        ? filterObjectArray(this.props.data, filter)
        : _recentReports;

    return _recentReports;
  }

  setSortField(field: string) {
    let _already_set = this.state.sortField["field"] === "field" ? true : false;

    let sorting =
      _already_set && this.state.sortField["sorting"] === "asc"
        ? "desc"
        : this.state.sortField["sorting"] === "asc"
        ? "desc"
        : "asc";
    this.setState({ sortField: { field, sorting } });
  }
  get sortWith(): object {
    return this.state.sortField;
  }
  setFilter(filter: string) {
    this.setState({ ...this.state, filter });
  }

  render() {
    return (
      <div className={`text-muted ${this.props.className}`}>
        <p className="font-weight-bold pt-3">
          <BarChartIcon />
          {this.props.title}
          {this.state.filter}
        </p>
        <div className="d-flex justify-content-end mb-1">
          <div>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search"
              onChange={(e) => this.setFilter(e.target.value)}
            />
          </div>
        </div>
        <table className="table table-striped data-table">
          <thead>
            <HeadFooterRow {...{ setSortField: this.setSortField }} />
          </thead>
          <tbody>
            {this.recentReports && this.recentReports.length ? (
              this.recentReports.map((obj: any, index: number) => (
                <Row key={index} obj={obj} index={index} />
              ))
            ) : (
              <tr>
                <td align="center" colSpan={10}>
                  <h6 className="text-muted text center">
                    {this.state.filter
                      ? `Nothing containing "${this.state.filter}" Found`
                      : "Nothing here yet"}
                  </h6>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <HeadFooterRow />
          </tfoot>
        </table>
      </div>
    );
  }
}
