import Status from "components/home/status";
import { connect } from "react-redux";
import { withRouter } from "react-router";

function _Row(props: any) {
  const reportURL: any = Object.values(props.modules).find(
    (i: any) => props.obj.package_id === i.package_id
  );

  return (
    <tr
      className="cursor-pointer"
      onClick={() => {
        const url = `/reports/${reportURL.url}/?request_ref=${props.obj.request_ref_number}&package_id=${props.obj.package_id}&dataset_name=${props.obj.dataset_name}`;
        props.history.push(url);
      }}
    >
      <td>{props.index + 1}</td>
      <td>{props.obj.dataset_name || "-"}</td>
      <td>{props.obj.registration_number || "-"}</td>
      <td>{props.obj.request_date || "-"}</td>
      <td>{props.obj.verified_date || "-"}</td>
      <td>
        <Status percentage={props.obj.percentage} status={props.obj.status} />
      </td>
    </tr>
  );
}

export const Row = connect(function (state: any) {
  return {
    modules: state.global.modules || {},
  };
})(withRouter(_Row));

export const HeadFooterRow = ({
  setSortField,
}: {
  setSortField?: (data: string) => any;
}) => {
  const _setSortField = setSortField ? setSortField : (param: any) => void null;
  return (
    <tr>
      <TH name="#">#</TH>
      <TH name="name" onClick={() => _setSortField("name")}>
        Name
      </TH>
      <TH
        name="registration_number"
        onClick={() => _setSortField("registration_number")}
      >
        Reg Number
      </TH>
      <TH name="date" onClick={() => _setSortField("date")}>
        Request Date
      </TH>
      <TH name="verified_date" onClick={() => _setSortField("verified_date")}>
        Report Date
      </TH>
      <TH name="status" onClick={() => _setSortField("status")}>
        Status
      </TH>
    </tr>
  );
};

// const Sort = () => <SortSharp className="text-info" style={styles.icon} />;

const TH = ({
  children,
  onClick,
}: {
  name: string;
  children: any;
  onClick?: any;
}) => (
  <th className="cursor-pointer" onClick={onClick ? onClick : () => void null}>
    {children}
  </th>
);
