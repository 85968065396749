import CheckBoxOutlineBlankRounded from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxRounded from "@material-ui/icons/CheckBoxRounded";
import { ApiNewRequest } from "api";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { State, store } from "store";
import { GlobalState } from "store/reducers/global";
import { sortBy, toggleModal } from "../../utils/functions";

export function NewRequest() {
  let state: State = store.getState();

  let _modules: any = Object.entries(state.global.modules).map((i: any) => ({
    ...i[1],
    module_id: i[0],
  }));

  let modules: GlobalState["modules"] = sortBy(_modules, "sort", "asc");

  const [key, setKey]: [any, any] = useState(null);
  const loading = key !== null ? true : false;

  useEffect(() => {
    toggleModal();
    return () => {
      toggleModal();
    };
  }, []);

  const [formData, setFormData] = useState({
    registration_number: null,
    dataset_name: null,
    terms_and_conditions: false,
    module_id: modules[0].module_id,
    package_id: modules[0].package_id,
  });

  const [error, setError]: [any, any] = useState(null);

  let history = useHistory();

  function goBack(e: any) {
    history.push("/");
  }
  const disableSubmission = key
    ? true
    : formData.dataset_name &&
      formData.module_id &&
      formData.registration_number &&
      formData.terms_and_conditions
    ? false
    : true;

  function handleSubmit(e: any) {
    e.preventDefault();
    if (disableSubmission) {
      return;
    }
    const _key = ApiNewRequest(formData, store, function (data) {
      if (data) {
        setError(data);
      } else {
        setFormData({
          module_id: null,
          package_id: null,
          dataset_name: null,
          registration_number: null,
          terms_and_conditions: false,
        });
        document.getElementsByTagName("form")[0].reset();
      }
      setKey(null);
    });
    setKey(_key);
  }

  function handleChange(e: any) {
    const key:
      | "module_id"
      | "package_id"
      | "registration_number"
      | "terms_and_conditions"
      | "dataset_name" = e.target.name;

    setFormData({ ...formData, [key]: e.target.value });
  }
  function setModule(module: any) {
    if (loading) {
      return;
    }
    setFormData({
      ...formData,
      module_id: module.module_id,
      package_id: module.package_id,
    });
  }
  let label = formData.module_id
    ? modules.find((i: any) => i.package_id === formData.package_id).label
    : "Company";

  return (
    <>
      <div
        className="container-fluid fixed-top"
        style={{ background: "rgba(0,0,0,.7)" }}
        onClick={goBack}
      >
        <div className="row justify-content-center vh-100 hide-scroll overflow-y-scroll">
          <div className="col-md-6 col-lg-5 col-11 py-4 flex-fill rounded-lg overflow-hidden">
            <div
              className="card blur-cont border-0 rounded-lg h-100 px-3"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="card-header bg-transparent border-secondary d-flex">
                <h4 className="m-0 col">New Request</h4>
                <button className="btn btn-sm text-danger" onClick={goBack}>
                  <h3 className="text-danger">&times;</h3>
                </button>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} action="#">
                  <label htmlFor="">Category</label>
                  <div className="form-row">
                    {modules &&
                      modules.map((module: any) => (
                        <div className=" col-12 col-lg-6" key={module.name}>
                          <div
                            onClick={() => setModule(module)}
                            className=" border-0 mb-1 cursor-pointer h-100 p-1"
                          >
                            <div className="card-body p-2 blur-cont h-100 rounded-sm d-flex justify-content-center flex-column">
                              <div className="d-flex align-items-center">
                                <p className="m-0 small w-100">{module.name}</p>
                                <div className="icon">
                                  {module.module_id === formData.module_id ? (
                                    <CheckBoxRounded className="text-primary" />
                                  ) : (
                                    <CheckBoxOutlineBlankRounded className="text-secondary" />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="py-2 d-md-block d-none"></div>
                  <div className="form-group">
                    <label htmlFor="">{`${label} Name`}</label>
                    <input
                      type="text"
                      className="form-control blur-cont border-0"
                      placeholder={`${label} Name`}
                      name="dataset_name"
                      onChange={handleChange}
                      disabled={loading}
                    />
                    {error?.dataset_name && (
                      <small className="text-danger floating-error">
                        {error?.dataset_name}
                      </small>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Registration Number</label>
                    <input
                      type="text"
                      className="form-control blur-cont border-0"
                      placeholder="Registration Number"
                      name="registration_number"
                      onChange={handleChange}
                      disabled={loading}
                    />
                    {error?.registration_number && (
                      <small className="text-danger floating-error">
                        {error?.registration_number}
                      </small>
                    )}
                  </div>
                  <div
                    className="checkbox align-items-center cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFormData({
                        ...formData,
                        terms_and_conditions: !formData.terms_and_conditions,
                      });
                    }}
                  >
                    <label htmlFor="">Terms & Conditions</label>
                    <div
                      className="d-flex align-items-center small"
                      style={{ lineHeight: "15px" }}
                    >
                      {formData.terms_and_conditions ? (
                        <CheckBoxRounded className="my-0 mr-1 text-primary" />
                      ) : (
                        <CheckBoxOutlineBlankRounded className="my-0 mr-1" />
                      )}
                      I accept terms and conditions and general policy of
                      Background Screening Request. I also accept that the data
                      set above mentioned has given consent for us to conduct
                      background screening
                    </div>
                  </div>
                  <hr className="d-none d-md-block" />
                  <div className="buttons pt-3">
                    {loading && (
                      <div className="spinner-border text-info spinner-sm"></div>
                    )}
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-sm btn-primary border-0 float-right py-2"
                      disabled={disableSubmission}
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
