import { StatsInterface } from "models";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps {
  new: number;
  in_progress: number;
  interim: number;
  final: number;
}
class _DashBoardStats extends Component<Props> {
  render() {
    return (
      <>
        <div className="row justify-content-center">
          <div
            className="col-md-3 col-sm-8 cursor-pointer"
            onClick={() =>
              this.props.history.push("/requests/?q=mine&status=new")
            }
          >
            <div
              className="card border-0"
              style={{ backgroundColor: " rgb(51, 0, 118)" }}
            >
              <div className="card-body py-3">
                <div className="main-card text-white">
                  <div className="card-body text-center">
                    <span style={{ fontSize: " 3em" }}>
                      <span>{this.props.new}</span>
                    </span>
                    <div className="widget-subheading">New Requests</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-3 col-sm-8 cursor-pointer"
            onClick={() =>
              this.props.history.push("/requests/?q=mine&status=in_progress")
            }
          >
            <div className="card border-0" style={{ backgroundColor: "blue" }}>
              <div className="card-body py-3">
                <div className="main-card  text-white bg-da">
                  <div className="card-body text-center">
                    <span style={{ fontSize: " 3em" }}>
                      <span>{this.props.in_progress}</span>
                    </span>
                    <div className="widget-subheading">In Progress</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-3 col-sm-8 cursor-pointer"
            onClick={() =>
              this.props.history.push("/requests/?q=mine&status=completed")
            }
          >
            <div className="card border-0" style={{ backgroundColor: "green" }}>
              <div className="card-body py-3">
                <div className="main-card text-white">
                  <div className="card-body text-center">
                    <span style={{ fontSize: " 3em" }}>
                      <span>{this.props.final}</span>
                    </span>
                    <div className="widget-subheading">Completed</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-8">
            <div className="card border-0" style={{ backgroundColor: "red" }}>
              <div className="card-body py-3">
                <div className="main-card text-white">
                  <div className="card-body text-center">
                    <span style={{ fontSize: " 3em" }}>
                      <span>0</span>
                    </span>
                    <div className="widget-subheading">Overdue</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any): StatsInterface => {
  return {
    new: state.global.stats?.new | 0,
    in_progress: state.global.stats?.in_progress | 0,
    interim: state.global.stats?.interim | 0,
    final: state.global.stats?.final | 0,
  };
};

export const DashBoardStats = connect(mapStateToProps)(
  withRouter(_DashBoardStats)
);
